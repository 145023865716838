<!--
 * @Author: zoucb
 * @Date: 2021-03-23
 * @LastEditors: zoucb
 * @FilePath: /src/views/service/chatStoreList.vue
-->
<!--客服聊天右侧列表 @zoucb-2021-03-23-->
<template>
    <div class="sld_chatRightList" :style="'height:' + (clientHeight - 180) + 'px'">
        <!-- 导航栏start -->
        <div class="header">
            <div class="header_item" @click="select(1)">
                <p :class="{ on: selectFlag == 1 }">我的订单</p>
                <div :class="{ bottom_line: selectFlag == 1 }"></div>
            </div>
            <div class="header_item" @click="select(2)">
                <p :class="{ on: selectFlag == 2 }">我的足迹</p>
                <div :class="{ bottom_line: selectFlag == 2 }"></div>
            </div>
            <div class="header_item" @click="select(3)">
                <p :class="{ on: selectFlag == 3 }">{{ curStoreId ? '供应商产品' : '平台产品' }}</p>
                <div :class="{ bottom_line: selectFlag == 3 }"></div>
            </div>
        </div>
        <!-- 导航栏end -->

        <!-- 搜索栏start -->
        <div class="search_wrap1 flex_row_start_center">
            <div class="search_con flex_row_between_center">
                <input type="text" v-model="keyword[selectFlag]" :placeholder="placeholder[selectFlag]">
                <span class="el-icon-close" @click="reset" v-show="keyword[selectFlag] != ''"></span>
            </div>
            <div class="do_search" @click="searchGo">搜索</div>
        </div>
        <!-- 搜索栏end -->


        <!-- 列表start -->
        <div class="list_wrap" v-if="selectFlag == 1" :style="'height:' + (clientHeight - 303) + 'px'">
            <!-- 订单列表 -->
            <el-scrollbar>
                <div class="orderOwn" v-infinite-scroll="load" infinite-scroll-immediate="false"
                    :infinite-scroll-distance="20">
                    <div class="orderOwn_item" v-for="(item, index) in orderlist.list" :key="index"
                        @click="toOrderInfo(item)">
                        <div class="order_header">
                            <div class="order_info">
                                <p class="orderSn">订单号：{{ item.orderSn }}</p>
                                <p class="orderDate">{{ item.createTime }}</p>
                            </div>
                            <div class="order_state">{{ item.orderStateValue }}</div>
                        </div>
                        <div>
                            <div :class="{ brief: true, order_con: true, sld_hide: item.isFold, sld_show: !item.isFold }"
                                v-for="(goodsItem, sub_index) in item.orderProductList" :key="sub_index">
                                <img :src="goodsItem.productImage" alt="">
                                <div class="text_con">
                                    <div>
                                        <p class="p1" :title="goodsItem.goodsName">{{ goodsItem.goodsName }}</p>
                                        <p class="p2" :title="goodsItem.specValues">{{ goodsItem.specValues }}</p>
                                    </div>
                                    <div class="bottom_con">
                                        <p v-if="goodsItem.goodsPrice">￥{{ goodsItem.goodsPrice }}</p>
                                        <div v-else>--</div>
                                        <a @click.stop="sendLink({ item, goodsItem }, 'order')">发送链接
                                            ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="openMore" @click.stop="openMore(item)" v-if="item.orderProductList.length > 1">
                            <span>{{ item.isFold ? '展开全部' : '收起全部' }}</span>
                            <i :class="item.isFold ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                        </div>
                    </div>
                    <loadingState v-if="loadState.orderState == 'first_loading' || orderlist.list.length > 0"
                        :state='loadState.orderState' />
                    <div class="empty_data" v-if="!orderlist.list.length && firstloading">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>暂无订单～</p>
                    </div>
                </div>
            </el-scrollbar>
        </div>

        <div class="list_wrap" v-if="selectFlag == 2" :style="'height:' + (clientHeight - 303) + 'px'">
            <el-Scrollbar>
                <div class="footPrint" v-infinite-scroll="load" infinite-scroll-immediate="false"
                    :infinite-scroll-distance="20">
                    <div class="footPrint_item" v-for="(foot, footIdx) in footlist.list" :key="footIdx">
                        <div class="brief footPrint_con" @click="toGoodsDetail(foot)">
                            <img :src="foot.goodsImage" alt="">
                            <div class="text_con">
                                <div>
                                    <p class="p1" :title="foot.goodsName">{{ foot.goodsName }}</p>
                                    <p class="p2" :title="foot.specValues">{{ foot.specValues }}</p>
                                </div>

                                <div class="bottom_con">
                                    <p class="price" v-if="foot.productPrice">￥{{ foot.productPrice }}</p>
                                    <div v-else>--</div>

                                    <a @click.stop="sendLink(foot, 'foot')">发送链接 ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <loadingState v-if="loadState.footState == 'first_loading' || footlist.list.length > 0"
                        :state='loadState.footState' />
                    <div class="empty_data" v-if="!footlist.list.length">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>暂无数据～</p>
                    </div>
                    <div></div>
                </div>
            </el-Scrollbar>
        </div>

        <div class="list_wrap" v-if="selectFlag == 3" :style="'height:' + (clientHeight - 303) + 'px'">
            <el-scrollbar>
                <div class="storeRecom" v-infinite-scroll="load" infinite-scroll-immediate="false"
                    :infinite-scroll-distance="20">
                    <div class="recomList">
                        <!-- <p class="title" v-if="curStoreId != 0">供应商推荐</p> -->
                        <div></div>
                        <div class="recom_item" v-for="(recom, index) in recomList.list" :key="index">
                            <div class="brief recom_con" @click="toGoodsDetail(recom)">
                                <img :src="recom.goodsImage" alt="">
                                <div class="text_con">
                                    <div>
                                        <p class="p1" :title="recom.goodsName">{{ recom.goodsName }}</p>
                                        <p class="p2" :title="recom.specValues">{{ recom.specValues }}</p>
                                    </div>
                                    <div class="bottom_con">
                                        <p class="price" v-if="recom.goodsPrice">￥{{ recom.goodsPrice }}</p>
                                        <div v-else>--</div>

                                        <a @click.stop="sendLink(recom, 'recom')">发送链接 ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <loadingState v-if="loadState.orderState == 'first_loading' || recomList.list.length > 0"
                            :state='loadState.orderState' />
                        <div class="empty_data" v-if="!recomList.list.length">
                            <img src="@/assets/goods/empty_data.png" alt="">
                            <p>暂无供应商产品～</p>
                        </div>
                    </div>
                    <div></div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive } from 'vue';
import loadingState from '@/components/loadingState'
// import { ElRate } from 'element-plus'
import { useRouter } from 'vue-router'
export default {
    name: 'chatRightList',
    components: {
        // ElRate,
        loadingState
    },
    setup(props, { emit }) {
        const selectFlag = ref(1)
        const isMore = ref(true)
        const select = (index) => {
            selectFlag.value = index
        }
        const curStoreId = ref('')
        const { proxy } = getCurrentInstance()
        const colors = ref(['#E2231A', '#E2231A', '#E2231A'])
        const score = ref(2)
        const firstloading = ref(false)
        const router = useRouter()
        const current = reactive({
            order: 1,
            foot: 1,
            recom: 1
        })
        const hasMore = reactive({
            orderMore: true,
            footMore: true,
            recomMore: true
        })
        const loadState = reactive({
            orderState: '',
            footState: '',
            recomState: ''
        })

        const openMore = (item) => {
            item.isFold = !item.isFold
        }
        const orderlist = reactive({
            list: []
        })
        const footlist = reactive({
            list: []
        })
        const recomList = reactive({
            list: []
        })

        const storeDetail = ref('')
        const keyword = reactive({
            1: '', 2: '', 3: ''
        })
        const placeholder = reactive({
            1: '订单号/采购单号/产品名称',
            2: '产品名称',
            3: '产品名称'
        })

        const getOrderList = () => {
            let params = {
                current: current.order,
                storeId: curStoreId.value
            }
            if (keyword[1]) {
                params.keyword = keyword[1]
            } else {
                delete params.keyword
            }
            loadState.orderState = 'first_loading'
            proxy.$get('v3/business/front/orderInfo/myOrders', params).then(res => {
                if (res.state == 200) {
                    if (current.order == 1) {
                        orderlist.list = res.data.list
                    } else {
                        orderlist.list = orderlist.list.concat(res.data.list)
                    }
                    hasMore.orderMore = checkPaginationHasMore(res.data.pagination)
                    if (hasMore.orderMore) {
                        current.order++
                        loadState.orderState = 'allow_loading_more';
                    } else {
                        loadState.orderState = 'no_more_data';
                    }
                    firstloading.value = true
                    orderlist.list.map(item => {
                        item.isFold = true
                    })
                }
            })
        }

        const getFootPrint = () => {
            let params = {
                current: current.foot,
                storeId: curStoreId.value
            }
            if (keyword[2]) {
                params.keyword = keyword[2]
            } else {
                delete params.keyword
            }
            proxy.$get('v3/member/front/productLookLog/myFootprint', params).then(res => {
                if (res.state == 200) {
                    if (current.foot == 1) {
                        footlist.list = res.data.list
                    } else {
                        footlist.list = footlist.list.concat(res.data.list)
                    }
                    hasMore.footMore = checkPaginationHasMore(res.data.pagination)
                    if (hasMore.footMore) {
                        current.foot++
                        loadState.footState = 'allow_loading_more';
                    } else {
                        loadState.footState = 'no_more_data';
                    }
                }
            })
        }

        const getRecomList = () => {
            let params = {
                current: current.recom,
                storeId: curStoreId.value,
                sort: 7
            }

            if (keyword[3]) {
                params.keyword = keyword[3]
            } else {
                delete params.keyword
            }

            proxy.$get('v3/goods/front/goods/goodsList', params).then(res => {
                if (res.state == 200) {
                    if (current.recom == 1) {
                        recomList.list = res.data.list
                    } else {
                        recomList.list = recomList.list.concat(res.data.list)
                    }
                    hasMore.recomMore = checkPaginationHasMore(res.data.pagination)
                    if (hasMore.recomMore) {
                        current.recom++
                        loadState.recomState = 'allow_loading_more';
                    } else {
                        loadState.recomState = 'no_more_data';
                    }

                }
            })
        }

        const getStoreDetail = () => {
            let params = {
                storeId: curStoreId.value
            }
            proxy.$get('v3/seller/front/store/detail', params).then(res => {
                if (res.state == 200) {
                    storeDetail.value = res.data
                    score.value = Number(res.data.comprehensiveScore)
                }
            })
        }

        const load = () => {
            if (selectFlag.value == 1 && hasMore.orderMore) {
                getOrderList()
            } else if (selectFlag.value == 2 && hasMore.footMore) {
                getFootPrint()
            } else if (selectFlag.value == 3 && hasMore.recomMore) {
                getRecomList()
            }
        }

        const checkPaginationHasMore = ({ current, pageSize, total }) => {
            return current * pageSize < total * 1;
        }

        const toGoodsDetail = (goods) => {
            let newWin = router.resolve({
                path: '/goods/detail',
                query: {
                    productId: goods.productId || goods.defaultProductId
                }
            })

            window.open(newWin.href, '_blank')
        }

        const toOrderInfo = (order) => {
            let newWin = router.resolve({
                path: '/member/order/info',
                query: {
                    orderSn: order.orderSn
                }
            })

            window.open(newWin.href, '_blank')
        }

        const sendLink = (obj, type) => {
            if (type == 'order') {
                let obj1 = {
                    orderSn: obj.item.orderSn,
                    createTime: obj.item.createTime,
                    orderStateValue: obj.item.orderStateValue,
                    goodsItem: obj.goodsItem
                }
                emit('getObj', obj1, type)
            } else {
                emit('getObj', obj, type)
            }

        }

        const getSwitch = (storeId) => {


            curStoreId.value = storeId
            current.recom = 1
            current.foot = 1
            current.order = 1
            keyword.value = ''

            if (storeId !== '') {
                getFootPrint()
                getRecomList()
                // getStoreDetail()
                getOrderList()
            } else {
                footlist.list = []
                orderlist.list = []
                recomList.list = []
            }


        }

        const searchGo = () => {
            if (!keyword[selectFlag.value]) return


            if (selectFlag.value == 1) {
                current.order = 1
                getOrderList()


            } else if (selectFlag.value == 2) {
                current.foot = 1
                getFootPrint()


            } else if (selectFlag.value == 3) {
                current.recom = 1

                getRecomList()
                // getStoreDetail()


            }

        }

        const reset = () => {
            keyword[selectFlag.value] = ''


            if (selectFlag.value == 1) {
                current.order = 1
                getOrderList()


            } else if (selectFlag.value == 2) {
                current.foot = 1
                getFootPrint()


            } else if (selectFlag.value == 3) {
                current.recom = 1

                getRecomList()
                // getStoreDetail()


            }
        }

        const clientHeight = ref(0)
        onMounted(() => {
            let clientHeight1 = document.body.clientHeight || document.documentElement.clientHeight
            clientHeight.value = clientHeight1

        })

        return {
            selectFlag,
            select,
            isMore,
            openMore,
            colors,
            score,
            orderlist,
            load,
            footlist,
            recomList,
            storeDetail,
            loadState,
            firstloading,
            sendLink,
            getSwitch,
            clientHeight,
            curStoreId,
            toGoodsDetail,
            toOrderInfo,
            keyword,
            searchGo,
            reset,
            placeholder
        }
    }
}
</script>

<style lang="scss">
.sld_chatRightList {
    height: 889px;
    width: 335px;
    border-left: 2px solid #f7f7f7;

    .header {
        width: 334px;
        height: 54px;
        background: #FFFFFF;
        display: flex;
        justify-content: space-around;

        .header_item {
            margin-top: 25px;
            height: 30px;


            p {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
            }

            .bottom_line {
                margin-top: 6px;
                background: $colorMain;
                border-radius: 2px;
            }

            .on {

                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: $colorMain;
            }


        }
    }

    .list_wrap {

        .orderOwn {
            .orderOwn_item {
                padding: 20px;
                border-bottom: 1px solid #f5f5f5;


                .sld_hide:not(:nth-child(1)) {
                    display: none;
                }

                .sld_show:not(:nth-child(1)) {
                    display: flex;
                }

                .order_header {
                    height: 37px;
                    border-bottom: 1px solid #f5f5f5;
                    display: flex;
                    justify-content: space-between;

                    .order_info {
                        .orderSn {
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                        }

                        .orderDate {
                            margin-top: 3px;
                            font-size: 12px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #999999;
                        }

                    }
                }

                .order_con {
                    margin-top: 20px;
                }

                .openMore {
                    margin-top: 20px;
                    text-align: center;
                    cursor: pointer;

                    span {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                    }

                    i {
                        margin-left: 10px;
                        font-size: 9px;
                    }

                    &:hover {

                        span,
                        i {
                            color: $colorMain
                        }
                    }
                }
            }
        }

        .footPrint {
            padding-left: 20px;


            .footPrint_item {
                padding: 20px 20px 20px 0;
                border-bottom: 1px solid #f5f5f5;

                .footPrint_con .price {
                    color: $colorMain2;
                }
            }
        }

        .storeRecom {


            .store_info {
                padding: 20px 20px 10px 20px;


                .store_name,
                .store_level,
                .store_contact {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 9px;
                }

                .store_level {
                    display: flex;
                }

                span {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .div_line {
                width: 295px;
                height: 1px;
                background: #F3F3F3;
                margin: 0 auto;
            }

            .store_score {
                padding: 20px;

                p:first-child {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-bottom: 15px;
                }

                p {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    margin-bottom: 10px;

                    span {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        color: #333333;
                    }
                }

                .highScore {
                    span {
                        color: $colorMain;
                    }
                }
            }

            .recomList {
                .title {
                    text-align: center;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                }

                .div_line_full {
                    margin-top: 9px;
                    height: 1px;
                    background: #000000;
                    opacity: 0.05;
                }

                .recom_con {
                    padding: 20px;
                }
            }
        }


    }

    .brief {
        display: flex;

        img {
            width: 69px;
            height: 66px;
            border-radius: 6px;
        }

        .text_con {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;

            p {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;




                &.p1 {
                    font-size: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }

                &.p2 {
                    font-size: 12px;
                    line-height: 17px;
                    color: #999999;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
            }

            .bottom_con {
                display: flex;
                justify-content: space-between;

                p {

                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: $colorMain2;
                }

                a {
                    cursor: pointer;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: $colorMain;
                }

                .price {
                    color: $colorMain2;
                }
            }
        }
    }


}

.empty_data {
    height: 300px;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 80px;
    }

    p {
        margin-left: 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #D5D5D5;
    }
}


.search_wrap1 {
    padding: 10px 20px;
    border-bottom: 1px solid #f5f5f5;

    .search_con {
        width: 240px;
        height: 32px;
        border: 1px solid #999999;
        padding: 0 10px;
        border-radius: 5px;

        input {
            width: 195px;
            border: none;
            outline: none;
            height: 30px;
            line-height: 30px;
        }

        .el-icon-close {
            font-size: 15px;
            color: #333333;
            line-height: 32px;
            cursor: pointer;
        }


    }

    .do_search {
        font-size: 14px;
        margin-left: 14px;
        color: #333333;
        cursor: pointer;
    }
}
</style>